<template>
  <app-modal
    :title="$t('quizVlm.articleModal.header')"
    @close="close"
  >
    <template slot="header">
      <button
        @click="addRelatedArticles"
        type="button"
        class="btn btn-green text-right"
        data-dismiss="modal"
        data-test="related_article_btn_select"
        :disabled="selectedArticles.length === 0"
      >
        <i class="fa fa-check"></i> {{ $t('article.modal.select') }}
      </button>
    </template>
    <template slot="body">
      <app-article-list-simple
        @close="close"
        @select-record="selectArticle"
        class="article-select-modal__list-simple"
      >
      </app-article-list-simple>
    </template>
  </app-modal>
</template>

<script>
import Modal from '@/components/shared/Modal'
import ArticleListSimple from '@/components/article/ArticleListSimple'

export default {
  name: 'ArticleSelectModal',
  data () {
    return {
      dataLoaded: false,
      selectedArticles: []
    }
  },
  components: {
    appModal: Modal,
    appArticleListSimple: ArticleListSimple
  },
  methods: {
    selectArticle (articles) {
      this.selectedArticles = articles
    },
    addRelatedArticles () {
      this.$emit('select-article', this.selectedArticles[0])
      this.$emit('close')
    },
    close () {
      this.$emit('close')
    }

  }
}
</script>

<style lang="scss">
  .article-select-modal {
    &__list-simple {
      .btn-article-create {
        display: none;
      }
    }
  }
</style>
