<template>
  <section>
    <div class="main-heading-text">
      {{ `${$t('quizVlm.answer.header')} *` }}
    </div>
    <Draggable :list="question.answers" handle=".answers-drag-drop-arrows">
      <div
        v-for="(answer, index) in question.answers"
        :key="`answer-${index}`"
        class="answers"
      >
        <div class="four-columns">
          <div class="four-columns__drag-drop align-middle-center answers-drag-drop-arrows">
            <i class="fa fa-arrow-up" />
            <i class="fa fa-arrow-down" />
          </div>
          <div class="four-columns__title">
            <Input
              v-model="answer.title"
              @blur="$v.question.answers.$each[index].title.$touch()"
              :error="$v.question.answers.$each[index].title.$error"
              :id="`answer_title-${index}`"
              :label="$t('quizVlm.answer.title')"
              required
              :disabled="disabled"
            />
          </div>
          <div class="four-columns__points">
            <SwitchCheckbox
              v-if="isQuizTypeCorrectAnswer"
              :value="Boolean(+answer.points)"
              @input="pointsChanged(index, $event)"
              id="quiz_displayCorrectAnswersImmediately"
              :true-label="$t('quizVlm.answer.pointsTrue')"
              :false-label="$t('quizVlm.answer.pointsFalse')"
              :disabled="disabled"
              class="four-columns__points__switch"
            />
            <Input
              v-else
              v-model="answer.points"
              @blur="$v.question.answers.$each[index].points.$touch()"
              :error="$v.question.answers.$each[index].points.$error"
              :id="`answer_points-${index}`"
              :label="$t('quizVlm.answer.points')"
              :min="0"
              type="number"
              required
              :disabled="disabled"
            />
          </div>
          <div class="four-columns__delete-button align-middle-center">
            <ModuleFormButton
              v-if="!disabled && question.answers.length > 2"
              icon="delete"
              @click="removeAnswer(index)"
            />
          </div>
        </div>
      </div>
    </Draggable>
    <button
      v-if="!disabled"
      class="add-button"
      :title="$t('quizVlm.answer.add')"
      @click="addAnswer"
    >
      <PlusIcon class="add-button__icon" />
      {{ $t('quizVlm.answer.add') }}
    </button>
  </section>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import PlusIcon from '@/assets/img/svg/plus.svg?inline'
import QuizAnswerModel from '@/model/QuizAnswerModelVlm'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import Draggable from 'vuedraggable'
import { minValue, required } from 'vuelidate/lib/validators'
import SwitchCheckbox from '@/components/form/SwitchCheckbox'
import { QUIZ_TYPE_CORRECT_ANSWER } from '@/components/mixins/valueObject/QuizTypeMixin'

export default {
  name: 'QuizFormAnswersVlm',
  props: {
    question: {
      type: Object,
      required: true
    },
    quizType: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModuleFormButton,
    Input,
    PlusIcon,
    Draggable,
    SwitchCheckbox
  },
  validations: {
    question: {
      answers: {
        $each: {
          title: {
            required
          },
          points: {
            required,
            minValue: minValue(0)
          }
        }
      }
    }
  },
  computed: {
    isQuizTypeCorrectAnswer () {
      return this.quizType === QUIZ_TYPE_CORRECT_ANSWER
    }
  },
  methods: {
    addAnswer () {
      const newAnswer = this._.cloneDeep(QuizAnswerModel)
      this.question.answers.push(newAnswer)
    },
    removeAnswer (index) {
      this.question.answers.splice(index, 1)
    },
    pointsChanged (index, booleanValue) {
      if (this.isQuizTypeCorrectAnswer) {
        if (booleanValue === true) {
          for (const answer of this.question.answers) {
            answer.points = Number(false)
          }
        } else {
          this.question.answers[Number(!index)].points = Number(true)
        }
        this.question.answers[index].points = Number(booleanValue)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main-heading-text {
  @include font(400 22px "Roboto");
  color: #465674;
  margin-top: 1.5rem;
  margin-bottom: rem(10px);
  padding-left: 2rem;
}
.align-middle-center {
  margin: auto;
  text-align: center;
}
.four-columns {
  display: flex;
  gap: 1%;
  &__drag-drop {
    flex: 0 0 4%;
    cursor: pointer;
    i {
      display: block;
    }
  }
  &__title {
    flex: 0 0 65%;
  }
  &__points {
    flex: 0 0 23%;
    &__switch {
      padding-top: rem(16px);
    }
  }
  &__delete-button {
    flex: 0 0 4%;
  }
}
.answers {
  padding-left: 1rem;
  padding-right: 1rem;
}
.add-button {
  @include font(700 13px "Roboto");
  @include padding(7px 14px);
  @include radius(4px);
  margin-top: 1rem;
  margin-left: 2rem;
  height: rem(30px);
  cursor: pointer;
  background: #6599fe;
  color: #fff;
  border: none;
  transition: background 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  @include bp(10) {
    &:hover {
      background: darken(#6599fe, 10%);
    }
  }
  &__icon {
    @include size(rem(14px));
    fill: #FFFFFF;
    float: left;
    margin-right: rem(8px);
  }
}
</style>
