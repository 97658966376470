<script>
import QuizNew from './QuizNewViewVlm'

export default {
  name: 'QuizEditViewVlm',
  extends: QuizNew,
  created () {
    this.getQuiz()
  }
}
</script>
