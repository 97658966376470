<template>
  <section>
    <div class="main-heading-text">
      {{ $t('quizVlm.resultsHeader') }}
    </div>
    <Draggable
      :list="quiz.results"
      :scroll-sensitivity="150"
      :fallback-tolerance="1"
      :force-fallback="true"
      @end="updateRichTextEditors"
      handle=".results-drag-drop-arrows"
    >
      <div
        v-for="(result, index) in quiz.results"
        :key="`result-${result.id ? result.id : result.fakeId}`"
        class="results"
      >
        <div class="four-columns">
          <div class="four-columns__drag-drop align-middle-center results-drag-drop-arrows">
            <i class="fa fa-arrow-up" />
            <i class="fa fa-arrow-down" />
          </div>
          <div class="four-columns__inputs">
            <Input
              v-model="result.score"
              id="resultScore"
              :label="$t('quizVlm.resultScore')"
              @blur="$v.quiz.results.$each[index].score.$touch()"
              :error="$v.quiz.results.$each[index].score.$error"
              type="number"
              :min="0"
              :disabled="disabled"
              required
              class="results__inputs__score"
            />
            <ModuleFormPhotoBox
              :key="`result-photobox-${index}`"
              :image="result.imageInfo"
              :disabled="disabled"
              :media-usage-type="mediaUsageType"
              :site="quiz.site"
              @set-media="(medias) => setImage(result, medias)"
              @remove-media="removeImage(result)"
              box-height="160px"
              hide-separator
            />
          </div>
          <div class="four-columns__title">
            <div class="title-text">
              {{ `${$t('quizVlm.resultTitle')} *` }}
            </div>
            <RichTextEditor
              v-model="result.description"
              @blur="$v.quiz.results.$each[index].description.$touch()"
              :key="`result-${result.id ? result.id : result.fakeId}-description-${result.updateKey ? result.updateKey : ''}`"
              :error="$v.quiz.results.$each[index].description.$error"
              :other-options="richTextEditorConfig"
              :id="`result-description-${index}`"
              :readonly="disabled"
            />
          </div>
          <div class="four-columns__delete-button align-middle-center">
            <ModuleFormButton
              v-if="!disabled"
              icon="delete"
              @click="removeResult(index)"
            />
          </div>
        </div>
      </div>
    </Draggable>
    <button
      v-if="!disabled"
      class="add-button"
      :title="$t('quizVlm.addResult')"
      @click="addResult"
    >
      <PlusIcon class="add-button__icon" />
      {{ $t('quizVlm.addResult') }}
    </button>
  </section>
</template>

<script>
import Vue from 'vue'
import { uuid } from 'vue-uuid'
import Input from '@/components/form/inputs/Input'
import PlusIcon from '@/assets/img/svg/plus.svg?inline'
import QuizResultModel from '@/model/QuizResultModelVlm'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import { MEDIA_USAGE_TYPE_QUIZ_RESULT } from '@/model/ValueObject/MediaUsageTypes'
import TinyMceConfig from '@/services/tinymce/TinyMceConfig'
import RichTextEditor from '@/components/form/RichTextEditor'
import Draggable from 'vuedraggable'
import { minValue, required } from 'vuelidate/lib/validators'

export default {
  name: 'QuizFormResultsVlm',
  props: {
    quiz: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModuleFormButton,
    ModuleFormPhotoBox,
    Input,
    PlusIcon,
    RichTextEditor,
    Draggable
  },
  data () {
    return {
      resultImageKey: 0,
      mediaUsageType: MEDIA_USAGE_TYPE_QUIZ_RESULT,
      richTextEditorConfig: {
        ...TinyMceConfig.getConfig(),
        height: 170,
        autoresize_min_height: 170
      }
    }
  },
  validations: {
    quiz: {
      results: {
        $each: {
          score: {
            required,
            minValue: minValue(0)
          },
          description: {
            required
          }
        }
      }
    }
  },
  methods: {
    updateRichTextEditors (dragEnd) {
      const start = dragEnd.oldIndex < dragEnd.newIndex ? dragEnd.oldIndex : dragEnd.newIndex
      const end = dragEnd.oldIndex < dragEnd.newIndex ? dragEnd.newIndex : dragEnd.oldIndex
      for (let i = start; i <= end; i++) {
        Vue.set(this.quiz.results[i], 'updateKey', (this.quiz.results[i].updateKey ?? 0) + 1)
      }
    },
    addResult () {
      const newResult = this._.cloneDeep(QuizResultModel)
      newResult.fakeId = uuid.v4()
      this.quiz.results.push(newResult)
    },
    removeResult (index) {
      this.quiz.results.splice(index, 1)
    },
    setImage (result, medias) {
      result.imageInfo = medias[0]
      result.image = medias[0].id
    },
    removeImage (result) {
      result.image = null
      result.imageInfo = null
    }
  }
}
</script>

<style scoped lang="scss">
.main-heading-text {
  @include font(400 22px "Roboto");
  color: #465674;
  margin-top: 1.5rem;
  margin-bottom: rem(10px);
}
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
.align-middle-center {
  margin: auto;
  text-align: center;
}
.four-columns {
  display: flex;
  gap: 1%;
  &__drag-drop {
    flex: 0 0 4%;
    cursor: pointer;
    i {
      display: block;
    }
  }
  &__inputs {
    flex: 0 0 21%;
    margin-top: 1rem;
  }
  &__title {
    flex: 0 0 67%;
    margin-top: 1.2rem;
  }
  &__delete-button {
    flex: 0 0 4%;
  }
}
.results {
  background-color: #efefef;
  margin-bottom: rem(10px);
  padding: 0.5rem;
}
.add-button {
  @include font(700 13px "Roboto");
  @include padding(7px 14px);
  @include radius(4px);
  margin-top: 1rem;
  height: rem(30px);
  cursor: pointer;
  background: #6599fe;
  color: #fff;
  border: none;
  transition: background 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  @include bp(10) {
    &:hover {
      background: darken(#6599fe, 10%);
    }
  }
  &__icon {
    @include size(rem(14px));
    fill: #FFFFFF;
    float: left;
    margin-right: rem(8px);
  }
}
</style>
