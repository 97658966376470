const removeElement = (htmlString, selectorToRemove, keepContent) => {
  const $wrapper = window.jQuery('<div></div>').html(htmlString)
  removeElemRecursively($wrapper, selectorToRemove, keepContent)

  return $wrapper.html()
}

const removeElemRecursivelyKeepContent = ($content, selectorToRemove) => {
  $content.children().each((index, child) => {
    const $child = window.jQuery(child)
    if ($child.is(selectorToRemove)) {
      const $parent = $child.parent()
      $child.after($child.html())
      $child.remove()
      removeElemRecursivelyKeepContent($parent, selectorToRemove)
    } else {
      removeElemRecursivelyKeepContent($child, selectorToRemove)
    }
  })
}

const removeElemRecursively = ($content, selectorToRemove, keepContent) => {
  if (keepContent) {
    removeElemRecursivelyKeepContent($content, selectorToRemove)
  } else {
    $content.find(selectorToRemove).each((index, elem) => {
      window.jQuery(elem).remove()
    })
  }
}

const removeSuccElemRecursively = ($content, predSelector, succSelectorToRemove, keepContent) => {
  $content.children().each((index, child) => {
    const $child = window.jQuery(child)
    if ($child.is(predSelector)) {
      removeElemRecursively($child, succSelectorToRemove, keepContent)
    } else {
      removeSuccElemRecursively($child, predSelector, succSelectorToRemove, keepContent)
    }
  })
}

const removeSuccElement = (htmlString, predSelector, succSelectorToRemove, keepContent) => {
  const $wrapper = window.jQuery('<div></div>').html(htmlString)
  removeSuccElemRecursively($wrapper, predSelector, succSelectorToRemove, keepContent)

  return $wrapper.html()
}

export default {
  removeElement,
  removeSuccElement
}
