import TinyMceParser from './TinyMceParser'
import store from '../../store/index'
const IS_VLM = store.getters.vlm

const vlmItems = [
  { title: 'Header 3', format: 'h3' },
  { title: 'Paragraph', format: 'p' }
]

const vlmExtendedItems = [
  { title: 'Header 3', format: 'h3' },
  { title: 'Header 4', format: 'h4' },
  { title: 'Header 5', format: 'h5' },
  { title: 'Paragraph', format: 'p' }
]

const nmhItems = [
  { title: 'Header 2', format: 'h2' },
  { title: 'Header 3', format: 'h3' },
  { title: 'Header 4', format: 'h4' },
  { title: 'Header 5', format: 'h5' },
  { title: 'Header 6', format: 'h6' },
  { title: 'Paragraph', format: 'p' }
]

const TINYMCE_SETTINGS_STYLE_FORMATS = [
  {
    title: 'Headers',
    items: IS_VLM ? vlmItems : nmhItems
  },
  {
    title: 'Inline',
    items: [
      { title: 'Bold', icon: 'bold', format: 'bold' },
      { title: 'Italic', icon: 'italic', format: 'italic' },
      !IS_VLM && { title: 'Underline', icon: 'underline', format: 'underline' }, // Only for NMH
      { title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough' },
      { title: 'Superscript', icon: 'superscript', format: 'superscript' },
      { title: 'Subscript', icon: 'subscript', format: 'subscript' }
    ].filter(Boolean) // Filter out 'false' values
  }
]

const getConfig = () => {
  return {
    element_format: 'html',
    entity_encoding: 'named',
    entities: '160,nbsp',
    theme: 'modern',
    // inline: true,
    height: 300,
    autoresize_min_height: 300,
    skin_url: '/tinymce/skins/lightgray',
    content_css: [
      '/tinymce/main.css',
      '/tinymce/font-awesome.css'
    ],
    language_url: '/tinymce/langs/sk.js',
    valid_children: '-p[div],-div[p]',
    plugins: [
      'autoresize',
      'link paste template advlist',
      'lists fullscreen wordcount',
      'table'
    ],
    default_link_target: '_blank',
    paste_as_text: true,
    paste_data_images: false,
    style_formats: TINYMCE_SETTINGS_STYLE_FORMATS,
    menubar: false,
    advlist_bullet_styles: 'default',
    advlist_number_styles: 'default,lower-alpha',
    toolbar: [
      'undo redo | cut copy paste selectall | styleselect | custom_headline_2 custom_headline_3 | bold italic ' +
      (!IS_VLM ? 'underline | ' : '') + // add "Underline" button for NMH
      'custom_button_unordered_list numlist | link | fullscreen'
    ],
    paste_preprocess: function (plugin, args) {
      let content = args.content
      content = TinyMceParser.removeElement(content, 'pre', true)
      content = TinyMceParser.removeSuccElement(content, 'h1', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h2', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h3', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h4', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h5', 'a', true)
      content = TinyMceParser.removeSuccElement(content, 'h6', 'a', true)
      args.content = content
    },
    setup: editor => {
      // add "Underline" button for NMH
      if (!IS_VLM) {
        editor.addButton('underline', {
          text: '',
          icon: 'underline',
          tooltip: 'Underline',
          onclick: function () {
            editor.execCommand('Underline')
          }
        })
      }
      editor.addButton('custom_button_unordered_list', {
        text: '',
        icon: 'bullist',
        tooltip: 'Bullet list',
        onclick: function () {
          editor.execCommand('InsertUnorderedList')
        }
      })
      if (!IS_VLM) {
        editor.addButton('custom_headline_2', {
          text: '',
          icon: ' mdi mdi-format-header-2',
          tooltip: 'Headline 2',
          onclick: function () {
            editor.execCommand('FormatBlock', false, 'h2')
          }
        })
      }
      editor.addButton('rubric_headline_2', {
        text: '',
        icon: ' mdi mdi-format-header-2',
        tooltip: 'Headline 2',
        onclick: function () {
          editor.execCommand('FormatBlock', false, 'h2')
        }
      })
      editor.addButton('custom_headline_3', {
        text: '',
        icon: ' mdi mdi-format-header-3',
        tooltip: 'Headline 3',
        onclick: function () {
          editor.execCommand('FormatBlock', false, 'h3')
        }
      })
      editor.addButton('custom_headline_4', {
        text: '',
        icon: ' mdi mdi-format-header-4',
        tooltip: 'Headline 4',
        onclick: function () {
          editor.execCommand('FormatBlock', false, 'h4')
        }
      })
      editor.addButton('strikethrough', {
        text: '',
        icon: ' strikethrough',
        tooltip: 'Strikethrough',
        onclick: function () {
          editor.execCommand('Strikethrough')
        }
      })
      editor.addButton('unlink', {
        text: '',
        icon: ' unlink',
        tooltip: 'Unlink',
        onclick: function () {
          editor.execCommand('unlink')
        }
      })
    }
  }
}

export default {
  getConfig,
  vlmExtendedItems
}
